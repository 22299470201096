/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 22번째 로그에서는 Write the Docs 후기, 애플 이벤트(애플 TV+, 애플 카드 등), 파이어폭스 계정 멀티 컨테이너에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/191/"
  }, "Write The Docs 서울의 2019 첫 번째 밋업 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/WTDSeoul"
  }, "Write The Docs Seoul (@WTDSeoul) | Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.slideshare.net/ssuser9ad176/to-137817951"
  }, "To. 지식 공유를 시작하려는 개발자에게 - LINE+ 홍연의")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/events/aws-summit-is-coming/"
  }, "AWS Summit 서울 행사가 시작됩니다! - 2019년 4월 17일-18일")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/apple-arcade/"
  }, "Apple Arcade - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/apple-tv-plus/"
  }, "Apple TV+ - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/newsroom/2019/03/highlights-from-apples-keynote-event/"
  }, "Highlights from Apple’s keynote event - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Khal_Drogo"
  }, "Khal Drogo - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Amazing_Stories_%28TV_series%29"
  }, "Amazing Stories (TV series) - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://play.hbonow.com/"
  }, "HBO Now")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Game_of_Thrones"
  }, "Game of Thrones - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hbo.com/documentaries/the-inventor-out-for-blood-in-silicon-valley"
  }, "The Inventor: Out for Blood in Silicon Valley | Documentaries | HBO")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://preview.disneyplus.com/kr/"
  }, "Disney+ 소개")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/List_of_original_programs_distributed_by_Apple"
  }, "List of original programs distributed by Apple - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.pooq.co.kr/"
  }, "푹 (POOQ)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.tving.com/main.do?retRef=Y&source=https://www.google.com/"
  }, "TVING｜TV를 더 즐겁게")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/itunes/"
  }, "iTunes - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/music/"
  }, "Music - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/gp/dmusic/promotions/PrimeMusic"
  }, "Amazon.com: Prime Music")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/apple-card/"
  }, "Apple Card - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/article/201903085312i"
  }, "코스트코 현대카드 출격…적립혜택 2배로 | 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.zeropay.or.kr/main.do"
  }, "제로페이")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ytn.co.kr/_ln/0104_201709090123468795"
  }, "국제 - '지갑 없는 사회' 도래...얼굴로 결제한다 - YTN")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.toutiao.com/"
  }, "今日头条")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.smartnews.com/ja/"
  }, "SmartNews")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wsj.com/"
  }, "The Wall Street Journal & Breaking News, Business, Financial and Economic News, World News and Video")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.readrapp.com/"
  }, "Readr | 10,000+ Magazines, One Subscription")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/taekie/status/1110672111545155584"
  }, "無異 on Twitter: \"애플 news+ 기존 pdf 잡지 구독 서비스와 차이점 ...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://it.chosun.com/site/data/html_dir/2018/12/12/2018121202776.html"
  }, "국세청, 구글코리아 세무조사…구글·아마존 과세 '신호탄'(종합) - IT조선")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mag2.com/p/news/348773"
  }, "なぜAmazonは日本で法人税を払わずに済むのか？ 元国税職員が解説 - まぐまぐニュース！")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://addons.mozilla.org/en-US/firefox/addon/tree-style-tab/"
  }, "Tree Style Tab – Get this Extension for 🦊 Firefox (en-US)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://addons.mozilla.org/en-US/firefox/addon/multi-account-containers/"
  }, "Firefox Multi-Account Containers – Get this Extension for 🦊 Firefox (en-US)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.choosyosx.com/"
  }, "Choosy: A smarter default browser for macOS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.mozilla.org/ko/kb/containers"
  }, "Multi-Account Containers | Firefox 도움말")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
